.about .card-content:last-child,
.about .card-footer:last-child,
.about .card-header:last-child
{
    min-height: unset;
}


.about .post .column{
    margin-right:0;
    margin-left:auto;
    padding-left:0;
}