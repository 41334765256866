.post{
    border: 1px solid #AAA;
    border-radius: 8px;
}

.post .card-header-title,
.card-padding{
    padding: 4px;
}

.post .card-footer .navbar{
    padding-left: 16px;
    padding-right: 16px;
}

.post .navbar-item{
    /* padding: 0 .1rem 0 .1rem; */
    height: 2rem;
}