.ajax-spinner-lightbox{
  display: none;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.ajax-spinner-lightbox.loading{
  display: block;
  position: fixed;
  z-index: 99;
  /* padding-top: 160px; */
  left: -100vw;
  top: -100vh;
  width: 300vw;
  height: 300vh;
  overflow: auto;
  background-color: rgba(206, 214, 219, .2);
  /* border: 1px solid black; */
  /* background-image: url('./img/spinner.gif'); */
  background-image: url('./img/gp-alpha.png');
  background-repeat: no-repeat;
  background-position: center;

  animation: rotation 1s infinite ease-in;
}