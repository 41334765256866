@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,500,500i,600,600i,700,700i&display=swap");

/* #glam-header:before {
  content: ' ';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url("../img/Villa-Harmony-Casa-LInda-Villas-9-1.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
} */

#glam-header .hero-body{
  padding-top: 1.5rem;
}
#glam-header .hero-head .title,
#glam-header .hero-head .subtitle,
#glam-header .hero-head a.navbar-item.is-active
{
  color: 0;

  /* color: white; */
  /* background-color: white; */
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;  
}
#glam-header .hero-head a.navbar-item{
  color: black;
}

#glam-header .navbar-item img{
  max-height: unset;
}
#glam-header .navbar-burger{
  top: 8px;
  scale: 1.4;
  margin-right: 24px;
}